<template>
  <page-wrapper
    :isLoading="isLoading"
    :hasFilters="true"
    @submitFilters="getNames"
  >
    <template #title>Fake names</template>
    <template #titleBtn>
      <b-button
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        class=""
        variant="success"
        @click="renderCreateModal"
      >
        + Add fake names
      </b-button>
    </template>
    <template #filters>
      <custom-date-picker
        @getSelectedDate="setFromDateFilter"
        inputLabel="From Date"
        inputPlaceholder="2023-12-06"
        inputId="fromDate"
      ></custom-date-picker>
      <custom-date-picker
        @getSelectedDate="setToDateFilter"
        inputLabel="To Date"
        inputPlaceholder="2023-12-06"
        inputId="toDate"
      ></custom-date-picker>
    </template>
    <custom-table
      v-if="requestsList"
      customIcon="CheckSquareIcon"
      customIconClass="text-success"
      :pageNumber="requestsListFilters.pageNumber"
      :count="requestsListFilters.count"
      :totalCount="totalCount"
      :tableItems="requestsList"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
    </custom-table>

    <create-new-fake-names-modal
      :isLoading="isLoading"
      :okCallback="createNewFakeNames"
    ></create-new-fake-names-modal>
  </page-wrapper>
</template>

<script>
import Helper from "@/libs/Helper";
import axios from "axios";

export default {
  title: "Fake names",
  mounted() {
    this.getNames();
  },
  data() {
    return {
      isLoading: false,
      totalCredit: "-",
      countries: [
        {
          id: 1,
          name: "USA",
        },
        {
          id: 2,
          name: "Iran",
        },
        {
          id: 3,
          name: "UK",
        },
        {
          id: 4,
          name: "UAE",
        },
      ],
      requestsListFilters: {
        pageNumber: 1,
        count: 10,
        fromDate: "",
        toDate: "",
      },
      totalCount: null,
      requestsList: null,
      myTableColumns: [
        {
          key: "userName",
          label: "User Name",
          formatter: (value) => value.trim() || "-",
        },
        // {
        //   key: "familyName",
        //   label: "Family Name",
        //   formatter: (value) => value.trim() || "-",
        // },
        {
          key: "createDate",
          label: "Create Date",
          formatter: (value) => new Date(value).toLocaleDateString("en-US"),
        },
        // {
        //   key: "countryId",
        //   label: "Country",
        //   formatter: (value) =>
        //     this.countries.find((el) => el.id === value).name,
        // },
      ],
      tempRequest: null,
    };
  },
  methods: {
    async getNames() {
      try {
        this.isLoading = true;
        let _this = this;
        let fakeNameGetAllRequest = new FakeNameGetAllRequest(_this);
        fakeNameGetAllRequest.setParams(_this.requestsListFilters);
        await fakeNameGetAllRequest.get((res) => {
          _this.totalCount = res.data.totalCount;
          _this.requestsList = res.data.data;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createNewFakeNames(file) {
      try {
        this.isLoading = true;

        let formData = new FormData();
        formData.set("file", file, file.name);
        formData.set("type", '0');

        axios({
          method: "POST",
          data: formData,
          withCredentials: true,
          url: Helper.baseUrl + "FakeName/ReadAndCreateFakeNames",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: getUserData().data.token,
          },
        }).then((response) => {
          if (response.data.isSuccess) {
            this.$bvModal.hide("create-new-fake-names");
            this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Confirmed!",
                icon: "CheckIcon",
                variant: "success",
                text: "Operation successful",
              },
            });
            window.location.reload();
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: response.data.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setToDateFilter(date) {
      this.requestsListFilters.toDate = date;
    },
    setFromDateFilter(date) {
      this.requestsListFilters.fromDate = date;
    },
    renderCreateModal() {
      this.$bvModal.show("create-new-fake-names");
    },
    changePage(pageNumber) {
      this.requestsListFilters.pageNumber = pageNumber;
      this.getNames();
    },
  },
  components: {
    CreateNewFakeNamesModal,
    CreateNewBonusModal,
    PageWrapper,
    CustomDatePicker,
    CashoutConfirmationModal,
    SearchInput,
    CustomTable,
    BButton,
    BBadge,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomDatePicker from "@/views/components/Utilities/CustomDatePicker.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import CashoutConfirmationModal from "@/views/components/Referral/CashoutConfirmationModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { BButton, BBadge } from "bootstrap-vue";
import CreateNewBonusModal from "@/views/components/Bonus/CreateNewBonusModal.vue";
import { AccountCreateFakeUser } from "@/libs/Api/Account";
import { FakeNameGetAllRequest } from "@/libs/Api/FakeNames";
import CreateNewFakeNamesModal from "@/views/components/User/CreateNewFakeNamesModal.vue";
import { getUserData } from "@/auth/utils";
</script>
